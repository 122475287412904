import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/Seo"
import {
  PrimaryHero,
  HeroCopyBlock
} from "../../../components/Heros"
import Spacer from "../../../components/Spacer";
import { DualImageBlock } from "../../../components/Grid"

import styles from "./styles.module.scss"
import codingImg from "./images/coding.png"
import meetingImg from "./images/meeting.png"
import officeImg from "./images/office.png"
import sortedlogoImg from "./images/sortedlogo.png"
import talldesksImg from "./images/talldesks.png"
import msgIcon from "./images/default.svg"

const Culture = () => (
  <Layout JobSection>
    <Seo title="Sorted | Culture" keywords={[`Sorted`, `Culture`]} />
    <PrimaryHero theme="pink">
      <HeroCopyBlock title={["Our unique", "culture."]}>
        <p>It’s not just about who we are, or where we are. It’s about how we do what we do.</p>
      </HeroCopyBlock>
    </PrimaryHero>

    <Spacer size="medium" sides="topbottom">
      <h2 className="text-center text-pink">The experience is everything.</h2>
    </Spacer>

    <div className={styles.genericPad}>
      <div className="container-large-fluid">
        <DualImageBlock img={meetingImg} theme="purple" maxHeight={650}>
          <div className={styles.designedByBlock}>
            <h3>Designed by our people.</h3>
            <p>Every element of our space was influenced and inspired by our people. Full consultation with our teams, and expert designers, ensured we created a space that was packed with useful, practical and fun details.</p>
          </div>
        </DualImageBlock>
      </div>
    </div>

    <div className={styles.genericPad}>
      <DualImageBlock reverse img={officeImg} theme="pink" maxHeight={600}>
        <h3>Team talk.</h3>
        <p>In the world of retail, things are busy. In the world of SaaS businesses, like ours, things are busy too. When we’re deep in it, we’re in it together.</p>
        <p>We pack our teams full of people who want to get stuck in. Those who collaborate, support each other, work closely and celebrate the wins and losses. The smart, brave, agile and pre-cise. Those who want to create tech wizardry, as if by magic. Like minded folk, but individuals nonetheless.</p>
      </DualImageBlock>
    </div>

    <div className={styles.genericPad}>
      <div className="container-large-fluid">
        <div className={styles.triBlock}>
          <div className={styles.leftImg}>
            <img src={codingImg} alt="people coding"/>
          </div>
          <div className={styles.content}>
            <div>
              <h3 className="text-pink">Delivering<br/>Development</h3>
              <p>We’ve built our very own personal development programme, which we affectionately call the ‘Getting Sorted Plan’. You work closely with your manager to discuss your career and put ac-tions together to build and improve the most important thing; you. Whether you want to hone existing skills, or learn brand new ones.</p>
            </div>
          </div>
          <div className={styles.rightImg}>
            <img src={talldesksImg} alt="People working at tall desks"/>
          </div>
        </div>
      </div>
    </div>

    <DualImageBlock img={sortedlogoImg} theme="grey" maxHeight={600}>
      <h3>Treats.</h3>
      <p>Eating, sleeping, playing; here’s just some of the stuff we get up to in our Tech House:</p>
      <div className={styles.bulletSection}>
        <div className={styles.bulletItem}>
          <img src={msgIcon} alt="message icon" className={styles.msgIcon}/>
          <p>Cheesy Tuesday</p>
        </div>
        <div className={styles.bulletItem}>
          <img src={msgIcon} alt="message icon" className={styles.msgIcon}/>
          <p>Pool league</p>
        </div>
        <div className={styles.bulletItem}>
          <img src={msgIcon} alt="message icon" className={styles.msgIcon}/>
          <p>Pizza dinners</p>
        </div>
        <div className={styles.bulletItem}>
          <img src={msgIcon} alt="message icon" className={styles.msgIcon}/>
          <p>Book club</p>
        </div>
        <div className={styles.bulletItem}>
          <img src={msgIcon} alt="message icon" className={styles.msgIcon}/>
          <p>Games nights</p>
        </div>
        <div className={styles.bulletItem}>
          <img src={msgIcon} alt="message icon" className={styles.msgIcon}/>
          <p>Spin</p>
        </div>
      </div>
      <p>We’ve done axe-throwing, jam sessions, lunch and learns, disco yoga, escape puzzles, cocktail making, valentine’s meals, Paddy’s Day karaoke, meet ups and many (possibly TOO many) nights out.” We’ve even had the Queen over for afternoon tea.</p>
    </DualImageBlock>

  </Layout>
)

export default Culture
